import React, { useEffect, useState, Fragment } from "react"
import MobileCheck from "components/tools/mobileCheck.js"
import Input from "components/tools/input.js"
import Title from "components/tools/InputTitle.js"
import * as styles from "components/member/find.module.css"
import { onFind } from "utils/memberUtils"
import { navigate } from "gatsby-link"
const FindComponents = props => {
  // console.log(props.option)  pwd or id
  // console.log(props.tool)  mobile or email

  const [main, setMain] = useState("")
  const [btnName, setBtnName] = useState("")
  const property = props.option + props.tool

  // useEffect(() => {
  //   console.log(1)
  //   setMain(null)
  // }, [property])

  useEffect(() => {
    if (!props.option || !props.tool) navigate("/login")

    if (props.option === "id") {
      setBtnName("아이디 찾기")
    } else if (props.option === "pwd") {
      setBtnName("비밀번호 찾기")
    }

    setMain(initialElement[property])
  }, [props, property])

  return (
    <div className={styles.con}>
      {main}
      <div
        className={`${styles.btn} longBtn`}
        onClick={() => {
          onFind(setMain, setBtnName, btnName, property)
        }}
      >
        {btnName}
      </div>
    </div>
  )
}

export default FindComponents

const initialElement = {
  none: (
    <Fragment key={"none"}>
      <div>reset</div>
    </Fragment>
  ),
  idmobile: (
    <Fragment key={"idmobile"}>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"이름"}
          tagname={"name"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
      <div>
        <Title style={{ fontFamily: "Noto Sans CJK KR" }} name="휴대전화" />
        <MobileCheck
          button={false}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
    </Fragment>
  ),
  idemail: (
    <Fragment key={"idemail"}>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"이름"}
          tagname={"name"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"이메일"}
          placeholder={"이메일 주소 입력"}
          tagname={"email"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
          left={
            <p style={{ marginTop: "5px", fontWeight: "300" }}>
              회원가입 시 등록한 이메일 주소를 입력하시면 아이디를 보내드려요.
            </p>
          }
        />
      </div>
    </Fragment>
  ),
  pwdmobile: (
    <Fragment key={"pwdmobile"}>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"아이디"}
          tagname={"id"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"이름"}
          tagname={"name"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
      <div>
        <Title style={{ fontFamily: "Noto Sans CJK KR" }} name="휴대전화" />
        <MobileCheck
          button={false}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
    </Fragment>
  ),
  pwdemail: (
    <Fragment key={"pwdemail"}>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"아이디"}
          tagname={"id"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"이름"}
          tagname={"name"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
        />
      </div>
      <div>
        <Input
          style={{ fontFamily: "Noto Sans CJK KR" }}
          title={"이메일"}
          placeholder={"이메일 주소 입력"}
          tagname={"email"}
          inputStyle={{ fontSize: "14px", fontWeight: "500" }}
          left={
            <p style={{ marginTop: "5px", fontWeight: "300" }}>
              회원가입 시 등록한 이메일 주소를 입력하시면 비밀번호를 보내드려요.
            </p>
          }
        />
      </div>
    </Fragment>
  ),
}
