import React from "react"
import Layout from "components/member/signUp/layout.js"
import * as styles from "./find.module.css"
import CheckBox from "components/tools/checkBox.js"
import queryString from "query-string"
import { navigate } from "gatsby-link"
import Findcomponent from "components/member/find"
const MemberFind = props => {
  const query = queryString.parse(props.location.search)

  const onCheck = ({ target }) => {
    //체크박스 하나만
    document.getElementsByName("checkbox").forEach(box => {
      box.checked = false
    })
    target.checked = true

    const content = target.closest("div").textContent

    const tool =
      content === "이메일" ? "email" : content === "휴대전화" ? "mobile" : ""

    if (tool) navigate(`?option=${query.option}&tool=${tool}`)
  }

  return (
    <Layout title={query?.option === "id" ? "아이디 찾기" : "비밀번호 찾기"}>
      <div className={`${styles.con} colContainer`}>
        {/* menu */}
        <div className={styles.menu}>
          <div className={styles.menuName}>
            <div
              onClick={() => {
                navigate(`?option=id&tool=${query.tool}`)
              }}
              style={{
                color: query?.option === "id" ? "#E95C63" : "#7c7c7c",
                cursor: "pointer",
              }}
            >
              아이디 찾기
            </div>
            <div
              onClick={() => {
                navigate(`?option=pwd&tool=${query.tool}`)
              }}
              style={{
                color: query?.option === "pwd" ? "#E95C63" : "#7c7c7c",
                cursor: "pointer",
              }}
            >
              비밀번호 찾기
            </div>
          </div>
          {/* 체크박스 */}
          <div className={styles.check}>
            <div>
              <CheckBox
                id={"mobileCheck"}
                name={"checkbox"}
                className={"circleCheckBox"}
                onClick={onCheck}
                checked={query.tool === "mobile"}
              />
              휴대전화
            </div>
            <div>
              <CheckBox
                id={"emailCheck"}
                name={"checkbox"}
                className={"circleCheckBox"}
                onClick={onCheck}
                checked={query.tool === "email"}
              />
              이메일
            </div>
          </div>
        </div>
        <Findcomponent option={query.option} tool={query.tool} />
      </div>
    </Layout>
  )
}
//  style={{ margin: "auto" }}

export default MemberFind
